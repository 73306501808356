@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
$theme-colors: ();

// $body-bg: #eeeeee;
$body-color: #222831;
$font-size-base: 0.875rem;
$font-family-sans-serif: 'Poppins', sans-serif;
$font-family-base: $font-family-sans-serif;

@import '~bootstrap/scss/bootstrap';

* {
  outline: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.navbar {
  height: 52px;
}

.bot {
  border-radius: 10px;
  overflow: hidden;
  height: 85%;
  display: flex;
  flex-direction: column;

  .bot-header {
    .online-dot {
      right: 0;
      bottom: 0px;
      height: 14px;
      width: 14px;
      border: 2px solid white;
      border-radius: 7px;
      background-color: limegreen;
    }
  }

  .bot-body {
    padding: 15px;
    height: 450px;

    .message {
      margin: 0;
      line-height: inherit;
      margin-bottom: 10px;
      max-width: 65%;
      min-width: auto;
      padding: 10px;
    }
  }

  .bot-footer {
    border-radius: 0 0 10px 10px;
  }
}

.space-y-4 {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.spin {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-file-input {
  cursor: pointer;
}

input[type='color'] {
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
  border: none;

  &:focus,
  &:active,
  &:hover {
    outline: none;
    border: none;
    box-shadow: none;
  }
}

#wrapper {
  overflow-x: hidden;
}

#sidebar-wrapper {
  margin-left: -15rem;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
    margin-left: -15rem;
  }
}

.CodeMirror {
  height: auto !important;
}

.modal-header {
  display: flex;
  align-items: center;
}
